<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { required, helpers } from "@vuelidate/validators";
import { reqMethods } from '../../../state/helpers';
// import { LMap, LMarker, LTileLayer, LIcon } from "@vue-leaflet/vue-leaflet";

/**
 * Email-read component
 */
export default {
  setup() {
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Détails de réponse",
      items: [
        {
          text: "Alertes Agent",
          href: "/agentAlertList"
        },
        {
          text: "Détails réponse",
          active: true
        }
      ],
      pertinence: "",
      citizen: null,
      alerte: null,
      responseData: null,
      audios: [],
      alerteReply: null,
      messages: [],
      audios: [],
      videos: [],
      files: [],
      photos: [],
      coodinates: [],
      position: {},
      reqError: null,
      submitted: false,
      map: null,
      userPosition: {},
      markers: [],
      form: {
        message: "",
      },
      username: "Steven Franklin",
    };
  },
  created() {
  },
  validations: {
    form: {
      message: {
        required: helpers.withMessage("Message is required", required),
      },
    },
  },
  methods: {
    ...reqMethods,
    initMap() {
      const self = this;
      console.log("init map")

      this.map = new google.maps.Map(this.$refs["map"], {
        zoom: 10,
        center: new google.maps.LatLng(self.userPosition.lat, self.userPosition.lng),
        mapTypeId: google.maps.MapTypeId.ROADMAP
      });


      new google.maps.Marker({
        position: { lat: self.userPosition.lat, lng: self.userPosition.lng },
        map: this.map,
        title: "Position Utilisateur",
        icon: {
          url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
          scaledSize: new google.maps.Size(50, 50),
        }
      });

      this.coodinates.forEach((item, index) => {
        new google.maps.Marker({
          position: { lat: item.coordinate.coordinates[0], lng: item.coordinate.coordinates[1] },
          map: this.map,
          title: `Position ${index + 1}`
        });

      });
      const centerControlDiv = document.createElement("div");
      // Create the control.
      const centerControl = self.CreateCenterControl(this.map);

      // Append the control to the DIV.
      centerControlDiv.appendChild(centerControl);
      this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(centerControlDiv);

      /*this.map.addListener("click", (e) => {
        this.handleMapClick(e)
      });*/
    },

    getDetailsReponseAlerte() {
      const that = this;
      this.getRequest(`/alert/alert-agent/reply/findbyid/?id=${this.$route.params.id}`)
        .then(
          function (response) {
            console.log('details')
            console.log(response.data)
            // that.citizen = response.data[0]?.sender;
            // that.alerte = response.data[0]?.alert_agent;
            that.audios = response.data?.audios;
            // that.alerteReply = response.data?.alert_agent;
            that.responseData = response.data[0];
            // console.log(that.citizen)

            // that.initMap();
          },
          function (error) {
            that.reqError = error;
          }
        )
    },
    setPertinence(pertinence) {
      this.pertinence = pertinence;
    },

  },
  mounted() {
    this.getDetailsReponseAlerte();
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <!-- Right Sidebar -->
      <div class="col-12 mb-3">
        <div class="card">
          <div class="card-body">
            <div class="d-flex mb-4">
              <img class="d-flex me-3 rounded-circle avatar-sm" src="@/assets/images/users/citoyen.png"
                alt="Generic placeholder image" />
              <!-- <h4 class="font-size-14 mt-3">{{ citizen?.first_name }} {{ citizen?.name }}</h4> -->
            </div>
            <div class="row mt-3 justify-content-between">
              <div class="col-md-2">
                <strong class="font-size-13 text-primary">Titre:</strong>
              </div>
              <div class="col-md-10">
                <!-- <h5><strong class="font-size-13">{{ alerte?.title }}</strong></h5> -->
              </div>
            </div>
            <div class="row mt-3 justify-content-between">
              <div class="col-md-2 text-primary">
                <strong class="font-size-13">Date:</strong>
              </div>
              <div class="col-md-10">
                <!-- <h5><strong class="font-size-13">{{ responseData?.created_at.split('T')[0] }} </strong></h5> -->
              </div>
            </div>
            <hr />
            <div class="p-3">
              <h5><strong>Audios: </strong></h5>
            </div>

            <div class="audio-player">
              <div class="audio-item" v-for="(audio, index) in audios" :key="index">
                <audio :src="`https://api.alcit.sims-technologie.com${audio.audio}`" controls></audio>
              </div>
            </div>
            <hr />

            <!-- <div class="form-group row mt-3">
              <div class="text-primary mb-3">
                <strong class="font-size-14">Carte:</strong>
              </div>
              <div class="col-lg-12">
                <l-map :zoom="zoom" style="height: 500px;" :center="center">
                  <l-tile-layer :url="url" :maxZoom="maxZoom" />
                  <l-marker :visible="true" :lat-lng="position">
                     v-for="( marker, index ) in Markers" :key="index"
                    <l-icon :icon-anchor="staticAnchor">
                      <img src="@/assets/images/leaflet/marker-icon.png" />
                    </l-icon>
                  </l-marker>
                </l-map>
              </div>
            </div> -->
          </div>
        </div>

        <div>
          <div class="row">
            <div class="col-lg-12">
              <section style="height: 700px;" ref="map" class="map"></section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
